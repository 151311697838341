import { Outlet, useHref, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import './index.css'
import NavBar from "./components/navBar";

export default function MainScreenLayout({children})
{
  return(
    <div className="app-wrapper">
      <Outlet />
      <NavBar/>
    </div>
  )
}