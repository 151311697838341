import React, { useEffect, useState } from "react";

export const useResMenuOptions = () => {

  const [mt, setMt] = useState(window.innerHeight / 2.25)
  const [transition, setTransition] = useState(0)
  const [over, setOver] = useState('hidden')

  const [height, setHeight] = useState(50)
  const [mb, setMb] = useState(0)
  const [pb, setPb] = useState(200)

  useEffect(() => {
    if(window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone) {
      setHeight(70)
      setMb(10)
      setPb(150)
    }
  }, [])
  
  useEffect(() => {

    const dragMenu = document.getElementById('drag-box')
    let isDragging = false;
    let offsetY;


    let delta = 1
    let endTouch = 0
    let startTouch = 0

    const swiper = document.querySelector('.image-swiper-wrpaper')

    swiper.addEventListener('touchstart', (e) => {
      startTouch = e.touches[0].clientY
    })

    swiper.addEventListener('touchmove', (e) => {
      endTouch = e.touches[0].clientY

      delta = startTouch - endTouch
    })

    swiper.addEventListener('touchend', (e) => {
      delta = 1
    })
    

    const contentBox = document.getElementById('drag-box-content')
    const header = document.getElementById('drag-box-header')

    header.addEventListener('touchmove', (e) => {
      e.preventDefault()
    })

    contentBox.addEventListener('touchstart', (e) => {
      startTouch = e.touches[0].clientY
    })

    contentBox.addEventListener('touchmove', (e) => {
      endTouch = e.touches[0].clientY

      delta = startTouch - endTouch
      if (delta < -200) {
        setTransition(0.5)
        delta = 1
      }
      if (delta > 200) {
        setTransition(0.5)
        delta = 1
      }
    })

    contentBox.addEventListener('touchend', (e) => {
      delta = 1
    })


    dragMenu.addEventListener('touchstart', (e) => {
      isDragging = true;
      offsetY = e.touches[0].clientY - dragMenu.offsetTop
    })

    document.addEventListener('touchmove', (e) => {
      if (delta !== 1) return
      if (isDragging) {
        setMt(e.touches[0].clientY - offsetY)
        if (dragMenu.offsetTop < 25) {
          isDragging = false
        }
        if (dragMenu.offsetTop > window.innerHeight - 50) {
          isDragging = false
        }
      }
    })
    
    document.addEventListener('touchend', (e) => {
      isDragging = false
    })

  }, [])

  const onDrag = () => {
    setTransition(0.25)
    let doneDrag = (mt / window.innerHeight).toFixed(2)

    if (doneDrag < 0) {
      setMt(25)
      setOver('scroll')
    }

    if (doneDrag > 0 && doneDrag < 0.25) {
      setMt(25)
      setOver('scroll')
    }

    if (doneDrag > 0.25 && doneDrag < 0.75) {
      setMt(window.innerHeight / 2)
      setOver('hidden')
    }

    if (doneDrag > 0.75) {
      setMt(window.innerHeight - (80 + height))
      setOver('hidden')
    }
    setTimeout(() => {
      setTransition(0)
    }, 250);
  }

  useEffect(() => {
    window.addEventListener('touchend', onDrag);
    
    return () => {
      window.removeEventListener('touchend', onDrag);
    };
  }, [mt]);

  const [dragMenuMt, setDragMenuMt] = useState(window.innerHeight / 2)

  useEffect(() => {
    if (mt < 25) return
    setDragMenuMt(mt)
  }, [mt])

  const [menuState, setMenuState] = useState('feed')

  useEffect(() => {
    const menuInput = document.getElementById('input-search')

    const hideMenu = () => {
      setMenuState('search')
    }

    menuInput.addEventListener('focus', (e) => hideMenu())

    return () => {
      menuInput.removeEventListener('focus', (e) => hideMenu())
    }
  }, [])

  useEffect(() => {
    if (menuState === 'feed') return
    const cancelButton = document.querySelector('.search-cancel-button-wrapper')
    const menuContent = document.getElementById('drag-box-content')

    const showMenu = () => {
      setMenuState('feed')
    }

    cancelButton.addEventListener('click', (e) => showMenu())

    return () => {
      cancelButton.removeEventListener('click', (e) => showMenu())
    }
  }, [menuState])

  return {
    mt,
    transition,
    over,
    dragMenuMt,
    height,
    mb,
    pb,
    menuState,
  }

}