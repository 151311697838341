import React, { useEffect, useState } from "react";

export const useEvents = () => {


  const events = [
    {
      title: 'BIG EvenTracker party',
      time: '9 декабря в 6:20',
      id: crypto.randomUUID(),
    },
    {
      title: 'Old EvenTracker party',
      time: '9 декабря в 6:20',
      id: crypto.randomUUID(),
    },
    {
      title: 'NO name party',
      time: '9 декабря в 6:20',
      id: crypto.randomUUID(),
    },
    {
      title: 'testlongnamingsffdsfdfsd',
      time: '9 декабря в 6:20',
      id: crypto.randomUUID(),
    }
  ]



  return {
    events
  }

 
}