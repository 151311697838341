import React, { useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import '../css/map.css'
import "mapbox-gl/dist/mapbox-gl.css";

const MainMap = ({height}) => {
  const mapContainerRef = useRef();
  const mapRef = useRef();

  useEffect(() => {
    mapboxgl.accessToken =  process.env.REACT_APP_TOKEN;

    const map  = new mapboxgl.Map({
      container: mapContainerRef.current,
      bearing: 0,
      language: 'ru-RU',
      minPitch: 35,
      pitch: 35,
      style: 'mapbox://styles/dmitriyv2044/cm0wfsxos00yd01qydikahqem',
      center: [73.429323, 61.260274], // starting position [lng, lat]
      zoom: 15.2, // starting zoom
    });

    mapRef.current = map

    // add a geolocation control
    mapRef.current.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true,
        showUserHeading: true,
      })
    );  
  }, []);

  return (
    <div
      style={{height: height}}
      ref={mapContainerRef}
      className="map-container"
    />
  );
};

export default MainMap;