import React, { useEffect, useState } from "react";
import { useScroll } from './useScroll'

export const useMapOptions = () => {

  const [mapHeight, setMapHeight] = useState(100)
  const { scrollPercent } = useScroll()

  return {
    mapHeight,
  }
}