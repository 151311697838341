import React, { useState } from 'react'
import '../css/resMenuSearch.css'
import StateSelector from './stateSelector'

function ResMenuSearch() {

  const [serchVariant, setSearchVariant] = useState('events')

  return (
    <div className='res-menu-search-wrapper'>
      <StateSelector strs={['Мероприятия', 'Организации']} setState={setSearchVariant} state={serchVariant}/>
      
    </div>
  )
}

export default ResMenuSearch