import React from 'react'
import '../css/resMenuFeed.css'
import friendsIcon from '../accets/IMGs/FriendsIcon.png'
import FriendsPlan from './friendsPlan';
import ActivityTag from './activityTag';
import AdsBanner from './adsBanner';
import ContactUs from './contactUs';
import { ReactComponent as ArrowRight} from '../accets/SVGs/ArrowRight.svg';
import { ReactComponent as BallIcon} from '../accets/SVGs/BallIcon.svg';
import { ReactComponent as BookIcon} from '../accets/SVGs/BookIcon.svg';
import { ReactComponent as RelaxIcon} from '../accets/SVGs/RelaxIcon.svg';
import { ReactComponent as TripleDot} from '../accets/SVGs/TripleDot.svg';
import { useEvents } from '../hooks/useEvents';
import ImageSwiper from './imageSwiper';
import EventCard from './eventCard';

export default function ResMenuFeed() {

  const { events } = useEvents()

  return (
    <div id='drag-box-content' className='responsive-menu-content'>
      <div className='content-tag'>
        <h1>Что то намечается</h1>
        <button className='arrow-right-wrapper'>
          <ArrowRight width={20} height={30}/>
        </button>
      </div>
      <ImageSwiper/>
      <div className='content-tag'>
        <h1>Чем заняться</h1>
      </div>
      <div className='block-tags'>
        <div className='your-friends-tag'>
          <div className='your-friends-tag-text-box'>
            <h1 className='your-friends-title'>Куда ходят ваши друзья?</h1>
            <h1 className='your-friends-des'>смотрите все мероприятия на карте</h1>
          </div>
          <img className='your-friends-icon' src={friendsIcon} alt=''/>
        </div>
        <div className='left-side-block-tags'>
          <EventCard event={events[0]} type={'map-ads'}/>
          <EventCard event={events[1]} type={'map-ads'}/>
        </div>
      </div>
      <FriendsPlan/>
      <div className='activity-box-wrpaper'>
        <ActivityTag text={'Активный отдых'} icon={<BallIcon width={30} height={30}/>}/>
        <ActivityTag text={'Спокойный отдых'} icon={<RelaxIcon width={30} height={30}/>}/>
        <ActivityTag text={'Умеренный отдых'} icon={<BookIcon width={30} height={30}/>}/>
        <ActivityTag text={'Все виды'} icon={<TripleDot width={30} height={30}/>}/>
      </div>
      <AdsBanner/>
      <div className='ads-expl'>
        <h1 className='ads-expl-text'>Реклама</h1>
      </div>
      <ContactUs/>
    </div>
  )
}
