import React, { useEffect, useState } from 'react'
import MainMap from './components/mainMap'
import { useMapOptions } from './hooks/useMapOptions'
import './css/map.css'
import MainMenu from './components/mainMenu'

function MainPage() {

  const { mapHeight } = useMapOptions()



  return (
    <div className='screen'>
      <div className='map-wrapper' style={{'--height': `${mapHeight}`}}>
        <MainMap height={`${mapHeight}vh`}/>
      </div>
      <MainMenu/>
    </div>
  )
}

export default MainPage