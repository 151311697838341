import React, { useEffect, useState } from "react";

export const useScroll = () => {

  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollPercent, setScrollPercent] = useState(0);
  const [scrollY, setScrollY] = useState(0);

  const handleScroll = (e) => {
    
  };





  return {
    scrollPosition,
    scrollPercent,
  }
}