import React from 'react';
import ReactDOM, { createRoot } from 'react-dom/client';
import ErrorPage from './error-page';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import './index.css'
import MainPage from './mainScreen';
import MainPageLayout from './mainSceenLayout';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/home" replace={true} />,
    errorElement: <ErrorPage />,
  },   
  {
    element: <MainPageLayout/>,
    children: [
      {
        path: "/home",
        element: <MainPage />,
      }, 
    ],
  },
]);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <RouterProvider router={router} />
);

