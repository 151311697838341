import React from 'react'
import '../css/friendsPlan.css'
import { ReactComponent as ArrowDown} from '../accets/SVGs/ArrowDown.svg';
import EventCard from '../components/eventCard'
import { useEvents } from '../hooks/useEvents'


function FriendsPlan() {

  const { events } = useEvents()
  

  return (
    <div className='friends-plan-wrapper'>
      <div className='content'>
        <div className='daySelector'>
          <h1 className='title'>У ваших друзей в планах</h1>
          <div className='dropdown'>
            <h1 className='day'>сегодня</h1>
            <ArrowDown className='arrowDown'/>
         </div>
        </div>
        <div className='cards-wrapper'>
          <EventCard event={events[0]} type={'map-friends'}/>
          <EventCard event={events[1]} type={'map-friends'}/>
          <EventCard event={events[2]} type={'map-friends'}/>
          <h1 className='-card-info'>и ещё 3</h1>
        </div>
      </div>
    </div>
  )
}

export default FriendsPlan