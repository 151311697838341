import React, { useEffect, useState } from "react";

export const useAds = () => {


	const adsDb = [
    {
      title: 'BIG EvenTracker party',
      time: '9 декабря в 6:20',
			des: 'Супер крутой ивент ваще сто пудов стоит сходить',
      id: crypto.randomUUID(),
    },
    {
      title: 'Old EvenTracker party',
      time: '9 декабря в 6:20',
			des: 'Супер крутой ивент ваще сто пудов стоит сходить',
      id: crypto.randomUUID(),
    },
    {
      title: 'NO name party',
      time: '9 декабря в 6:20',
			des: 'Супер крутой ивент ваще сто пудов стоит сходить',
      id: crypto.randomUUID(),
    },
    {
      title: 'testlongnamingsffdsfdfsd',
      time: '9 декабря в 6:20',
			des: 'Супер крутой ивент ваще сто пудов стоит сходить',
      id: crypto.randomUUID(),
    }
	]


  const [ads, setAds] = useState(adsDb[0])



  useEffect(() => {
		let index = 0;
    const changeAds = setInterval(() => {
			index++
      setAds(adsDb[index % adsDb.length])
    }, 5000);
    
    // очистка интервала
    return () => clearInterval(changeAds);
  }, []);




  return {
    ads,
  }

 
}